$(document).ready(function() {

    if ($('.swiper-container--activities').length) {
        let activitiesSwiper = new Swiper('.swiper-container--activities', {
            slidesPerView: "auto",
            simulateTouch: false,
            spaceBetween: 24,
            navigation: {
                nextEl: '.activities-next',
                prevEl: '.activities-prev',
            },
            breakpoints: {
                600: {
                    spaceBetween: 50,
                }
            },
        });

        activitiesSwiper.forEach(function(el) {
            el.snapGrid = el.slidesGrid;
        });
    }

    if ($('.swiper-container--industries').length) {
        let industriesSwiper = new Swiper('.swiper-container--industries', {
            slidesPerView: "auto",
            navigation: {
                nextEl: '.industries-next',
                prevEl: '.industries-prev',
            },
        });

        industriesSwiper.snapGrid = industriesSwiper.slidesGrid;
    }

    if ($('.swiper-container--steps').length) {
        let stepsSwiper = new Swiper('.swiper-container--steps', {
            slidesPerView: "auto",
            spaceBetween: 24,
        });

        stepsSwiper.snapGrid = stepsSwiper.slidesGrid;
    }

    $('.popup-btn').on('click', function(e) {
        e.preventDefault();

        $('body').css('overflow', 'hidden');

        $('.popup').show();

        setTimeout(() => {
            $('.popup__layer').css({
                'opacity': '1'
            });

            setTimeout(() => {
                $('.popup__box').css({
                    'opacity': '1'
                });
            }, 200);
        }, 100);
    });

    $('.popup__close-btn')
        .add('.popup__confirm-btn')
        .on('click', function(e) {
            e.preventDefault();

            $('.popup__box').css({
                'opacity': '0'
            });

            setTimeout(() => {
                $('.popup__layer').css({
                    'opacity': '0'
                });

                setTimeout(() => {
                    $('.popup').hide();

                    $('body').css('overflow', 'auto');
                }, 100);
            }, 200);
        });

    $('.contact-us-link').on('click', function(e) {
        e.preventDefault();

        $('body').css('overflow', 'hidden');

        $('.contact-us').show();

        setTimeout(() => {
            $('.contact-us__overlay').css({
                'opacity': '1'
            });

            setTimeout(() => {
                $('.contact-us__container').css({
                    'opacity': '1'
                });
            }, 200);
        }, 100);
    });

    $('.contact-us__close-btn').on('click', function(e) {
        e.preventDefault();

        $('.contact-us__container').css({
            'opacity': '0'
        });

        setTimeout(() => {
            $('.contact-us__overlay').css({
                'opacity': '0'
            });

            setTimeout(() => {
                $('.contact-us').hide();

                $('body').css('overflow', 'auto');
            }, 100);
        }, 200);
    });

    $('.js-mobile-navbar-open-btn').on('click', function(e) {
        e.preventDefault();

        var $this = $(this);

        if ($('body').hasClass('__mobile-navbar-opened')) {
            $('body').removeClass('__mobile-navbar-opened');
            $this.html('<i class="ri ri-menu-line"></i>');
            $('.mobile-header__bottom').slideUp();
        } else {
            $('body').addClass('__mobile-navbar-opened');
            $this.html('<i class="ri ri-close-line"></i>');
            $('.mobile-header__bottom').slideDown();
        }
    });

});

$(window).on('load', function() {

    //

});